import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionRoute from "./Route/ConditionRoute";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const CreateCollection = lazy(() => import("./Collection/CreateCollection"));
const ListCollection = lazy(() => import("./Collection/ListCollection"));
const ListCollectionNFT = lazy(() => import("./Collection/CollectionsNfts"));

const CreateCategory = lazy(() => import("./Category/CreateCategory"));
const ListCategory = lazy(() => import("./Category/ListCategory"));

const MintNFT = lazy(() => import("./nftMint/MintNFT"));
const ListNFT = lazy(() => import("./nftMint/ListNFT"));
const SensitiveListNFT = lazy(() => import("./nftMint/sensitiveNFTList"));

const UsersList = lazy(() => import("./Users/UsersList"));
const UsersReport = lazy(() => import("./Users/UserReport"));

const BidList = lazy(() => import("./Bids/BidList"));

const AddServiceFee = lazy(() => import("./Settings/addServiceFee"));

const Buttons = lazy(() => import("./basic-ui/Buttons"));
const Dropdowns = lazy(() => import("./basic-ui/Dropdowns"));
const Typography = lazy(() => import("./basic-ui/Typography"));

const UserReport = lazy(() => import("./Reports/UsersReport"));
const NFTReport = lazy(() => import("./Reports/NFTReport"));
const CollectionReport = lazy(() => import("./Reports/CollectionReport"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const TermCondition = lazy(() => import("./Cms/TermCondition"));
const PrivacyPolicy = lazy(() => import("./Cms/PrivacyPolicy"));
const Events = lazy(() => import("./Event/event"));

const ChartJs = lazy(() => import("./charts/ChartJs"));

const Login = lazy(() => import("./user-pages/Login"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Lockscreen = lazy(() => import("./user-pages/Lockscreen"));

const ListEmail = lazy(() => import("./NewsLetter/ListEmail"));
const MailTemplate = lazy(() => import("./NewsLetter/MailTemplate"));

const ChangePassword = lazy(() => import("./shared/ChangePass"));
const TransactionDetails = lazy(() => import("./Category/transactionDetails"));

const EventList =lazy (()=>import("./Event/eventlist"))
export default function AppRoutes() {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <ConditionRoute
          path="/dashboard"
          component={Dashboard}
          type={"private"}
        />
        <ConditionRoute
          path="/collection/create-collection"
          component={CreateCollection}
          type={"private"}
        />
        <ConditionRoute
          path="/collection/list-collection"
          component={ListCollection}
          type={"private"}
        />
        <ConditionRoute
          path="/collection/list-collection-nfts/:id"
          component={ListCollectionNFT}
          type={"private"}
        />
        <ConditionRoute
          path="/changepassword"
          component={ChangePassword}
          type={"private"}
        />
        
        <ConditionRoute
          path="/category/create-category"
          component={CreateCategory}
          type={"private"}
        />
        <ConditionRoute
          path="/category/list-category"
          component={ListCategory}
          type={"private"}
        />
        <ConditionRoute
          path="/nft/nft-mint"
          component={MintNFT}
          type={"private"}
        />
        <ConditionRoute
          path="/nft/nft-list"
          component={ListNFT}
          type={"private"}
        />
        <ConditionRoute
          path="/nft/SensitiveListNFT"
          component={SensitiveListNFT}
          type={"private"}
        />
        <ConditionRoute
          path="/users/users-list"
          component={UsersList}
          type={"private"}
        />
        <ConditionRoute
          path="/users/users-report"
          component={UsersReport}
          type={"private"}
        />
        <ConditionRoute
          path="/bids/bids-list"
          component={BidList}
          type={"private"}
        />
        <ConditionRoute
          path="/settings/add-service-fee"
          component={AddServiceFee}
          type={"private"}
        />
        <ConditionRoute
          path="/newsletter/list-email"
          component={ListEmail}
          type={"private"}
        />
        <ConditionRoute
          path="/newsletter/mail-template"
          component={MailTemplate}
          type={"private"}
        />
        <ConditionRoute
          path="/report/user-report"
          component={UserReport}
          type={"private"}
        />
        <ConditionRoute
          path="/report/nft-report"
          component={NFTReport}
          type={"private"}
        />
        <ConditionRoute
          path="/report/collection-report"
          component={CollectionReport}
          type={"private"}
        />
        <ConditionRoute
          path="/cms/term-condition"
          component={TermCondition}
          type={"private"}
        />
        <ConditionRoute
          path="/cms/privacy-policy"
          component={PrivacyPolicy}
          type={"private"}
        />
        <ConditionRoute
          path="/cms/events"
          component={Events}
          type={"private"}
        />
        <ConditionRoute
          path="/cms/EventList"
          component={EventList}
          type={"private"}
        />
        
        <ConditionRoute
          path="/nft/TransactionDetails"
          component={TransactionDetails}
          type={"private"}
        />
       

        {/* <ConditionRoute path="/basic-ui/buttons" component={Buttons} />
        <ConditionRoute path="/basic-ui/dropdowns" component={Dropdowns} />
        <ConditionRoute path="/basic-ui/typography" component={Typography} /> */}

        {/* <ConditionRoute
          path="/form-Elements/basic-elements"
          component={BasicElements}
        /> */}

        {/* <ConditionRoute path="/tables/basic-table" component={BasicTable} />

        <ConditionRoute path="/charts/chart-js" component={ChartJs} /> */}

        <ConditionRoute
          path="/user-pages/login-1"
          component={Login}
          type={"auth"}
        />
        {/* <ConditionRoute path="/user-pages/register-1" component={Register1} />
        <ConditionRoute path="/user-pages/lockscreen" component={Lockscreen} />

        <ConditionRoute path="/error-pages/error-404" component={Error404} />
        <ConditionRoute path="/error-pages/error-500" component={Error500} />

        <ConditionRoute
          path="/general-pages/blank-page"
          component={BlankPage}
        /> */}

        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );
}

import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

import isLogin from "../lib/isLogin";

const ConditionRoute = ({
  component: Component,
  layout: Layout,
  type,
  store,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (type == "auth" && isLogin() == true) {
          return <Redirect to="/dashboard" />;
        } else if (type == "private" && isLogin() != true) {
          return <Redirect to="/user-pages/login-1" />;
        }

        if (Layout) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ConditionRoute;
